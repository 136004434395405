<template>
  <v-sheet :color="wsBACKGROUND" class="d-flex align-center justify-center fill-height">
    <div v-show="loggedIn && !$store.state.auth.user.phone">
      <v-sheet :color="wsBACKGROUND" class="fill-height">
        <ws-dialog
            :value="true"
            @save="addPhoneNumber"
            :title="$t('EnterPhoneNumber')"
            persistent
            hide-close
        >

          <h4 :style="`color : ${wsACCENT}`" class="mb-10 mt-5 text-center">
            {{ $t('NoPhoneDashboardWarning') }}
          </h4>

          <ws-phone-input
              :label="$t('Phone')"
              v-model="phoneNumber"
              :error-messages="phoneError"
              :error="!!phoneError"
              @input="phoneError = false"
              :hide-details="false"
          />
        </ws-dialog>


      </v-sheet>
    </div>
    <div>
      <v-progress-circular v-if="loading"  size="72" :color="wsACCENT" indeterminate />
      <view-button-course-enroll
          v-else-if="entity.uuid && entity.active"
          :entity="entity"
          :display="triggerDisplayEnroll"
          no-dialog
      >
        <template #auth_header>
          <div class="d-flex justify-center">
            <img v-if="$store.state.project === 'westudy'"   width="150px" alt="WeStudy" src="@/assets/brand/logo_westudy_color.png" />
          </div>
          <h4 class="text-center my-7"> {{ $t('EnrollOnCourse') }}:<br> <span :style="`color : ${wsACCENT}`" >{{ entity.name }}</span></h4>
        </template>
      </view-button-course-enroll>

      <h1 :style="`color : ${wsACCENT}`" v-else-if="!loading && !entity.uuid">{{ $t('CourseNotFound') }}</h1>
    </div>
  </v-sheet>
</template>

<script>
import viewButtonCourseEnroll from "@/components/AvalonEditor/View/elements/button/viewButtonCourseEnroll";
import {mapActions, mapMutations, mapState} from "vuex";
export default {
  name: "CourseRegistrationPage",
  components : {
    viewButtonCourseEnroll
  },
  props : {
    uuid : {
      type: String
    },
    alias : {
      type : String
    }
  },
  data() {
    return {
      loading : true,
      entity : {},
      triggerDisplayEnroll : false,

      phoneNumber : null,
      phoneError : null

    }
  },
  computed : {
    ...mapState('auth', [  'loggedIn' , 'user'  ]),
  },
  watch : {
    loggedIn() {
      this.initPage()
    }
  },
  methods : {
    ...mapActions('courses', [
        'GET_COURSE_BUTTON_PUBLIC' ,
        'GET_COURSE_BUTTON'
    ]),
    ...mapActions('business', ['GET_BUSINESS']),
    ...mapActions('auth', ['ADD_PHONE_NUMBER' , 'CHECK_EMAIL']),
    ...mapMutations('auth',['LOGOUT']),

    async addPhoneNumber() {
      if ( !this.phoneNumber) {
        this.phoneError = this.$t('EnterPhone')
        return this.notify(this.$t('EnterPhone') , 'warning')
      }

      if ( !await this.CHECK_EMAIL({ type : 'phone', phone : this.phoneNumber }) ) {
        this.phoneError = this.$t("UserAlreadyExists");
        return this.notify(this.$t('UserAlreadyExists') , 'warning')
      }

      let result = await this.ADD_PHONE_NUMBER({ phone : this.phoneNumber })
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      this.$store.state.auth.user.phone = result.phone
      this.triggerDisplayEnroll++
    },

    logout() {
      this.$store.state.dashboard.showLogoutConfirm = false
      this.LOGOUT(this.$router)
    },

    prepareBusinessData(out) {
      this.$store.state.templates.selectedBusiness = out.data;
      this.$store.state.business.selectedBusiness = out.data;
      this.$store.state.templates.businessAlias = out.data.alias;
      this.$store.state.business.selectedBusiness.alias = out.data.alias;
      this.$store.state.business.selectedBusiness.modules = out.data.modules;
      this.template = out.data.template
      this.$store.state.templates.templateAlias = out.data.template
      this.business = out.data.alias
      this.$store.state.templates.businessName = out.data.name;
      this.$store.state.templates.businessTemplate = out.data.template;
      this.$store.state.templates.products = out.data.products
      this.$store.state.templates.product_categories = out.data.product_categories
      this.$store.state.templates.news.reverse()
      this.$store.state.templates.locations = out.data.locations
      this.$store.state.templates.deliveryAllowCountries = out.data.deliveryAllowCountries
      this.$store.state.templates.businessCountry = out.data.country
      this.$store.state.templates.deliveryCountries = out.data.deliveryCountries
      this.$store.state.business.selectedBusiness.currency = out.data.currency;


      if ( out.data.products ) {
        this.$store.state.templates.rentedProducts = out.data.products.filter(el=> el.parentProduct)
      }


      if ( out.data.deliveries) {
        this.$store.state.cart.deliveries = []
        out.data.deliveries.forEach( (delivery)=>{
          this.$store.state.cart.deliveries.push({text:delivery.name,value:delivery.id})
        })
      }

      if (out.data.payments) {
        this.$store.state.cart.payments = []
        out.data.payments.forEach( (payment)=>{
          this.$store.state.cart.payments.push({text:payment.name,value:payment.id})
        })
      }

    },
    async initPage(){
      if ( !this.loggedIn) {
        this.entity = await this.GET_COURSE_BUTTON_PUBLIC(this.uuid) || {}
      } else {
        this.entity = await this.GET_COURSE_BUTTON(this.uuid) || {}
      }
      if ( this.entity.business_alias ) {
        this.$store.state.business.selectedBusiness.alias = this.entity.business_alias
      }
      this.triggerDisplayEnroll++
      this.loading = false
    },
  },
  beforeMount() {
    this.$store.state.avalon.editorMode = false
  },
  mounted() {
    this.GET_BUSINESS( this.$store.state.domain ? location.host : this.alias ).then(async (out)=> {
      this.prepareBusinessData(out)
      await this.initPage()
      this.loading = false
      setTimeout(()=>{
        this.loading = false
        this.triggerDisplayEnroll++
      } , 300)
    }).catch(() => this.loading = false)

  }
}
</script>

<style scoped>

</style>